.card-contact {
    .hover {
        display: none;
    }

    &:hover {
        .hover {
            display: block;
        }
    }
}
